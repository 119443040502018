<template lang='pug'>
div.form__wrapper
  router-link(to='/')
    img.logo(src='../assets/logo.png')
    h1 You're all set!
  p Your proton.ai account has been created with the provided credentials.

  router-link(to='/') 
    el-button(type='text') Log in now
</template>

<script>
import api from '../api'

export default {
  name: 'ConfirmAccount'
}
</script>

<style lang='scss'>
@import '../scss/common';
</style>